@media only screen and (max-width: 768px) {
  .hero-text h1 {
    font-size: 3rem;
  }

  .hero-text p {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 480px) {
  .hero-text h1 {
    font-size: 26px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .hero-text p {
    font-size: 1rem;
  }
}

@media (max-width: 991px) {
  .header {
    top: 0 !important;
    border-bottom: #eee 1px solid;
    padding: 15px 20px !important;
  }

  main {
    margin-top: 76px;
  }

  .hero {
    margin: 0 1rem 25px;
  }

  .hero,
  .hero-text {
    height: 550px;
  }

  .hero-text p {
    margin-bottom: 30px;
    width: 80%;
  }

  .section-bg {
    background-color: #eee;
    color: #18181b;
  }

  section {
    padding: 3rem 1rem !important;
  }

  .mobile-nav {
    right: 0;
    top: 75px;
    z-index: 999;
    border-top: 1px solid #eee;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    height: calc(100% - 76px);
  }
  .mobile-nav a {
    padding: 10px 20px;
    font-size: 18px;
  }

  .mobile-nav .active {
    font-weight: 600;
  }

  .mobile-nav-show {
    transform: translateX(0);
  }

  .project-image {
    height: 200px !important;
  }

  .page-hero,
  .page-hero .hero-text {
    height: 268px;
  }

  .seperator {
    height: 1px;
    background: #eee;
    margin: auto;
    margin-bottom: 1rem;
    width: 88%;
  }

  .cta-button,
  .cta-button:hover {
    padding: 0 !important;
    transition: 0.2s ease-in-out;
    border-radius: 9999px;
    font-size: 14px;
    width: max-content;
  }

  .cta-button i {
    font-size: 22px;
  }

  .bg-opac {
    background: rgb(0 0 0 / 50%);
    transition: opacity 0.3s ease-in-out;
  }

  .blog, .work, .tools, .contact {
    padding: 5rem 0;
  }
}