.header,
.mobile-nav-toggle,
.navbar a,
.navbar a:focus,
.navbar a:hover,
a {
  transition: .3s ease-in-out
}

body {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  color: #18181b !important
}

a {
  color: inherit !important;
  text-decoration: none !important
}

img {
  -webkit-user-drag: none
}

.divider {
  border-color: #b8b8b8 !important;
  margin: auto
}

.header {
  position: fixed;
  z-index: 9999;
  background-color: #fff;
}

.navbar ul {
  gap: 2rem
}

.navbar .active,
.navbar li a:hover {
  font-weight: 600;
}

.navbar-brand {
  font-size: 30px !important;
  font-family: Eurofighter, sans-serif;
}

.hero,
.hero-text {
  height: 585px;
}

.hero-text h1 {
  margin-bottom: 18px;
  font-weight: 750;
}

.hero-text p {
  width: 100%;
  font-size: 18px;
  color: #57534e
}

.section-bg {
  background: #18181a;
  color: #fff;
}

footer ul li i {
  padding-right: 10px;
  font-size: 18px;
}

.section-title {
  padding-bottom: 30px
}

.section-title h2 {
  font-size: 32px;
  color: #18181b
}

.section-title p {
  color: #939393
}

.mobile-nav-toggle {
  color: #18181b;
  font-size: 32px;
  cursor: pointer;
  line-height: 0;
  transform: rotate(0);
  transition: 0.3s ease-in-out;
}

.mobile-nav {
  display: none
}

.section-bg .section-title h2 {
  font-size: 32px
}

.project-card .project-type {
  font-size: .875rem;
  line-height: 1.25rem;
  letter-spacing: .1em;
}

.project-card h3 {
  font-size: 22px;
}

.project-image {
  background-image: url(https://via.placeholder.com/356x236);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100%;
  height: 250px;
  border-radius: 10px;
  position: relative;
}

.project-card .content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  border: #eee 1px solid;
}

.project-card .content i {
  font-size: 22px;
}

.blog, .work, .tools, .contact {
  padding: 3rem 0;
}

.cta-button,
.cta-button:hover {
  background-color: #18181a;
  padding: 8px 20px;
  transition: .2s ease-in-out;
  border-radius: 9999px;
  font-size: 14px;
  width: max-content;
}

.about {
  padding: 60px 0;
}

.tool-card h3 {
  font-size: 22px;
}

.tool-card .icon-container {
  background: #eee;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.tool-card .content .tool-name {
  font-size: 17px;
}

.tool-card {
  flex: 1 1 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.tool-card .icon-container img {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.tool-card:hover .icon-container img {
  transform: scale(0.8);
  transition: transform 0.3s ease-in-out;
}

main {
  margin-top: 80px;
}

.page-hero,
.page-hero .hero-text {
  height: 268px;
}

.project-card .blog-info {
  font-size: .875rem;
  line-height: 1.25rem;
  letter-spacing: .1em;
}

.blog-info span:first-child::after {
  display: inline-block;
  margin: 0 .5rem .2rem;
  content: "";
  font-size: 1rem;
  line-height: .5rem;
  padding: 2px;
  border-radius: 50%;
  vertical-align: middle;
  background-color: currentColor;
}

.error-page-hero {
  height: calc(100vh - 155px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.go-home-btn,
.go-home-btn:hover {
  background-color: #18181a;
  padding: 8px 20px;
  transition: .2s ease-in-out;
  border-radius: 5px;
  font-size: 14px;
  width: max-content;
}

.contact-submit,
.contact-submit:hover {
  background-color: #18181a;
  padding: 10px 20px;
  transition: .2s ease-in-out;
  border-radius: 6px;
  font-size: 14px;
  width: max-content;
}

.see-more,
.see-more:hover {
  padding: 0 !important;
  transition: 0.2s ease-in-out;
  border-radius: 9999px;
  font-size: 14px;
  width: max-content;
}

.see-more i {
  font-size: 22px;
}

.notification-top-bar {
  line-height: 40px;
  font-size: 14px;
  transition: .3s ease-in-out;
}

input,
textarea {
  box-shadow: none !important;
  outline: none !important;
  border-color: #dee2e6 !important;
}